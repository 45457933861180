import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap";

// bootstrap 导入
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

// 自定义CSS
import './assets/css/product.css'


createApp(App).mount('#app')
